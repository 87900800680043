@import url(https://fonts.googleapis.com/css2?family=Itim&family=Open+Sans:wght@300&display=swap);
html,
body,
#root {
  margin: 0px;
  padding: 0px;
  height: 100%;
}

.InputElement {
  font-size: 30px;
}

::-webkit-scrollbar {
  display: none;
}

